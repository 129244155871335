@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
// @import "/src/app/styles/_variables.scss";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/translucent.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: calc(100vh - 80px);
  height: 100%;
  max-height: 100vh;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #000;
}

// Hide Arrows of Number Input Field
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.primary-btn {
  @apply rounded-xl text-white text-sm font-medium leading-[21px] text-center bg-blue-700 hover:bg-blue-800 active:bg-blue-800 active:ring-[3px] active:ring-blue-100;
}

.delete-btn {
  @apply rounded-xl text-white text-sm font-medium leading-[21px] text-center bg-red-500 hover:bg-red-600 active:bg-red-700 active:ring-[3px] active:ring-red-100;
}

.secondary-btn {
  @apply rounded-xl text-gray-800 text-sm font-medium leading-[21px] text-center ring-1 ring-gray-200 bg-white hover:bg-blue-800 hover:text-white active:ring-[3px] active:ring-blue-100;
}

.card {
  @apply shadow-[0px_2px_4px_-2px_#0000000D,0px_4px_6px_-1px_#0000001A] bg-gray-50 rounded-xl md:py-6 md:px-8 p-3;
}

#tippy-2 {
  width: fit-content;
  border-radius: 4px;
  .tooltip__content {
    @apply text-xs font-normal leading-[18px] text-center text-white bg-gray-800;
  }
}

// Scrollbar style
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #ebf5ff;
  border-radius: 20px;
  margin: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #76a8ed;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1f6fe0;
}

body .tox-tbtn--select[title="Personalize List"] {
  @apply bg-gray-200 rounded py-0.5 px-3 text-gray-900 leading-[21px] text-sm font-semibold;

  span {
    overflow: unset;
    @apply font-semibold w-max;
  }
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tox-tinymce-aux {
  z-index: 9999999 !important;
}

.fade-in-animation{
  background-color: white;
  opacity: 1 !important;
  background-color: white;
  .dropdown-list-container{
    background-color: white;
    animation: fade_in 0.3s 0s ease;
    -webkit-animation: fade_in 0.3s 0s ease;
  }
}

@keyframes fade_in{
  0%{
    opacity: 0 !important;
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
  }
  50%{
    opacity: 1 !important;
    transform: scale(1.03);
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    -o-transform: scale(1.03);
}
  100%{
    opacity: 1 !important;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
}
svg {
  color: #9CA3AF;
}
.datepicker-dropdown{
  z-index: 9999;
}

.fade-in-delay-1{
  opacity: 0;
  animation: fade-in 0.25s 0.8s linear  forwards;
  -webkit-animation: fade-in 0.25s 0.8s linear  forwards;
}
@keyframes fade-in{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

* {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE
}

html, body, app-root {
  // overflow: hidden; // Prevent scrolling globally
  overflow: auto; // Prevent scrolling globally
}

image-cropper{
  &>div{
    height: 60vh;
    width: auto !important;
  }
  img {
    width: auto;
    height: auto;
    object-fit: contain;
  }
}
